<template>
  <div class="bbbb">
    <div class="pc">
      <div class="conser">
        <div class="conser_img">
          <img src="../../assets/image/errorPage/Frame41.png" alt="">
        </div>
        <div class="box_32">
          <p>Coming Soon...</p>
        </div>
        <div class="but_box">
          <button class="but_boxs" @click="$router.push('/')">Homepage</button>
        </div>

      </div>
    </div>
    <div class="yido">
      <div class="conser">
        <div class="conser_img">
          <img src="../../assets/image/errorPage/Frame41.png" alt="">
        </div>
        <div class="box_32">
          <p>Coming Soon...</p>
        </div>
        <div class="but_box">
          <button class="but_boxs" @click="$router.push('/')">Homepage</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
body {
  background: #f8f8f8;
}
@media (max-width: 991px) {
  .pc {
    display: none;
  }
  .conser {
    height: 100%;
    background: #f8f8f8;
    padding: 0 60px;
    // display: flex;
    // margin: 0 auto;
    .conser_img {
      width: 100%;
      // height: 550px;
      margin: 0 auto;
      img {
        width: 100%;
        // height: 550px;
        padding: 156px 0 40px 0;
      }
    }
  }
  .box_32 {
    width: 232px;
    height: 42px;
    font-size: 32px;
    font-weight: 400;
    color: #999999;
    line-height: 38px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 50px;
    border: 0;
  }
  .but_box {
    width: 100%;
    padding-bottom: 120px;
  }
  .but_boxs {
    width: 184px;
    height: 60px;
    border: 0;
    border-radius: 54px 54px 54px 54px;
    text-align: center;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    background: #c7000b;
    display: block;
    margin: 0 auto;
  }
}
@media (min-width: 991px) {
  .yido {
    display: none;
  }
  .conser {
    height: 100%;
    background: #f8f8f8;
    // display: flex;
    // margin: 0 auto;
    .conser_img {
      width: 900px;
      height: 550px;
      margin: 0 auto;
      img {
        width: 900px;
        height: 550px;
        padding: 106px 0 50px 0;
      }
    }
  }
  .box_32 {
    width: 232px;
    height: 42px;
    font-size: 32px;
    font-weight: 400;
    color: #999999;
    line-height: 38px;
    text-align: center;
    margin: 50px auto;
    border: 0;
  }
  .but_box {
    width: 100%;
    padding-bottom: 120px;
  }
  .but_boxs {
    width: 184px;
    height: 60px;
    border: 0;
    border-radius: 54px 54px 54px 54px;
    text-align: center;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    background: #c7000b;
    display: block;
    margin: 0 auto;
  }
}
</style>
